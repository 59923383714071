export const determineTurn = (matchStatus, user, legNo) => {
  const p1BullScore = matchStatus.p1?.bull?.score;
  const p2BullScore = matchStatus.p2.bull?.score;
  const isP1Turn = p1BullScore > p2BullScore;
  const isUserP1 = user.username === matchStatus.p1?.name;
  const turnOrder = [true, false, true, false, true];

  if (isUserP1) {
    return isP1Turn ? turnOrder[legNo % 5] : !turnOrder[legNo % 5];
  } else {
    return isP1Turn ? !turnOrder[legNo % 5] : turnOrder[legNo % 5];
  }
};
