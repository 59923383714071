import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal";

const ScoringModal = ({ isOpen, modalType, onClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [score, setScore] = useState(0);

  const onChange = (e) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > 3) {
      setScore("");
    } else {
      setScore(e.target.value);
    }
  };

  const handleSubmitScore = () => {
    handleSubmit(score);
  };
  return (
    <CustomModal title={t(modalType)} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 mt-8">
        <div>
          <label className="text-lg font-semibold" htmlFor="missed">
            {t(modalType)}
          </label>
          <input
            type="number"
            id="missed"
            name="missed"
            className="w-full p-2 border border-gray-300 rounded-md"
            value={score}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="flex mt-4">
        <button
          className="bg-green-500 text-white font-semibold py-2 px-4 rounded-md ml-auto"
          onClick={handleSubmitScore}
        >
          Submit
        </button>
      </div>
    </CustomModal>
  );
};

export default ScoringModal;
