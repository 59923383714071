import ScoringUserAvatar from "./ScoringUserAvatar";

const ScoringAvatarContainer = ({
  user,
  userHistory,
  userCurrentScore,
  wins,
  turn,
}) => {
  return (
    <div className="flex flex-col gap-2 sm:gap-8 z-10">
      <ScoringUserAvatar
        user={user}
        currentScore={userCurrentScore}
        wins={wins}
        turn={turn}
      />
      {userHistory?.length ? (
        <div className="flex flex-col overflow-y-auto border rounded-md p-1 sm:p-4 z-10">
          {[...userHistory].reverse()?.map((score, index) => (
            <span key={index} className="text-white text-lg sm:text-2xl font-semibold">
              {score}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ScoringAvatarContainer;
