import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div className="py-4">
      <div className="flex flex-wrap border-b border-b-gray-400 mx-4">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`px-3 py-2 text-sm sm:px-5 sm:py-2.5 sm:text-xl cursor-pointer outline-none ${
              activeTab === tab.label
                ? "font-bold border text-white bg-green-500 dark:bg-gray-900 border-gray-300 rounded-t-md border-b-2 border-b-green-600 dark:border-b-gray-900 shadow-md"
                : "border-none"
            }`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="px-3 sm:px-5">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`${activeTab === tab.label ? "block" : "hidden"}`}
          >
            {activeTab === tab.label && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;