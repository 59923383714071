import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import socket from "../socket";
import { usePrice } from "./usePrice";
import {
  addUser,
  fetchAllusers,
  updateStamina,
  updateAllUsers,
  fetchUserInfo,
} from "../app/authSlice";
import { postEvent } from "../app/eventSlice";
import { updateMatchResults } from "../app/arenaSlice";

import notificationSound from "../assets/audio/notification.mp3";
import arenaXpSound from "../assets/audio/xp-sound.MP3";

export const useSocketEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth);
  const {
    customPrice,
    defaultPrice,
    globalCoins,
    updateCustomPrice,
    updateGlobalCoin,
  } = usePrice();

  // const userID = userState?.user?._id;
  const users = userState?.allUsers;
  const user = userState?.user;

  // const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [toastId, setToastId] = useState(null);

  const playAudio = useCallback(() => {
    const audio = new Audio(notificationSound);
    audio.play().catch((err) => console.log("Audio--err-->>", err));
  }, []);

  const playArenaXpAudio = useCallback(() => {
    const arenaXpAudio = new Audio(arenaXpSound);
    arenaXpAudio.play().catch((err) => console.log("Audio--err-->>", err));
  }, []);

  const handleErr = useCallback((err) => {
    console.log("Socket--err-->>", err);
  }, []);

  const handleUserID = useCallback(({ userID }) => {
    console.log("user_id--->>>", userID);
    socket.userID = userID;
  }, []);

  const updateUsersStatus = useCallback(
    (status) => {
      dispatch(updateAllUsers({ ...user, status }));
    },
    [dispatch, user]
  );

  const handleUserStatusUpdate = useCallback(
    ({ updatedUsersList }) => {
      updatedUsersList.forEach((user) => {
        dispatch(updateAllUsers(user));
      });
      console.log("receivedusers=>>>>", updatedUsersList);
    },
    [dispatch]
  );

  const updateUsers = useCallback(
    async (receivedUsers) => {
      receivedUsers.forEach((user) => {
        const existingUserIndex = users.findIndex(
          (existingUser) => existingUser._id === user._id
        );

        if (existingUserIndex !== -1) {
          dispatch(updateAllUsers(user));
        } else {
          dispatch(addUser(user));
        }
      });
    },
    [dispatch, users]
  );

  const updateUserConnectionStatus = useCallback(
    (isConnected) => (user) => {
      const { status, userID } = user;
      let connectedUser = {
        ...users.find((val) => val._id === userID),
        status,
      };
      dispatch(updateAllUsers(connectedUser));
    },
    [users, dispatch]
  );

  const handleNotifications = useCallback((notifications) => {
    setNotifications(notifications);
  }, []);

  const requestNotificationPermission = useCallback(async () => {
    if (Notification.permission === "granted") {
      return true;
    } else if (Notification.permission !== "denied") {
      const permission = await Notification.requestPermission();
      return permission === "granted";
    }
    return false;
  }, []);

  const showNotification = useCallback((title, options) => {
    if (Notification.permission === "granted") {
      new Notification(title, options);
    }
  }, []);

  const handleDesktopNotification = useCallback(
    async (notification) => {
      const hasPermission = await requestNotificationPermission();
      if (hasPermission && document.visibilityState === "hidden") {
        showNotification("Fight Club Notification", {
          body: notification?.message,
        });
      }
    },
    [requestNotificationPermission, showNotification]
  );

  const handleNotification = useCallback(
    (notification) => {
      playAudio();

      handleDesktopNotification(notification);

      if (!toast.isActive("notification")) {
        toast.info(notification.message, { toastId: "notification" });
      }

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
    },
    [playAudio, handleDesktopNotification]
  );

  const handleQuickResponse = useCallback(
    (
      toId,
      opponent,
      challenger,
      paymentOption,
      scoringOption,
      notification,
      token,
      type
    ) => {
      if (paymentOption === "payHalf") {
        if (customPrice > 0) {
          updateCustomPrice(user._id, "custom", -0.5);
        } else if (defaultPrice > 0) {
          updateCustomPrice(user._id, "default", -0.5);
        } else if (globalCoins > 0) {
          updateGlobalCoin(-0.5);
        } else {
          toast.error("You don't have enough coins to accept the challenge.");
          socket.emit("challenge-decline", {
            message: `${user?.username} can't accept the challenge due to insufficient coins`,
            to: toId,
          });
          return;
        }
      }

      dispatch(fetchUserInfo());

      socket.emit("quick-accept", {
        toId,
        opponent,
        challenger,
        paymentOption,
        scoringOption,
        token,
        type,
      });

      handleNotificationClick(notification);

      if (scoringOption === "lidarts") {
        window.open(`https://lidarts.org`, "_blank");
        if (type === "quick") {
          navigate(`/result/${token}/quick`);
        } else if (type === "top-quick") {
          navigate(`/result/${token}/top-quick`);
        }
      }
       else {
        navigate(
          `/scoring/${challenger?.toLowerCase()}/${challenger?.toLowerCase()}/${token}`
        );
      }
    },
    [
      dispatch,
      user,
      customPrice,
      defaultPrice,
      globalCoins,
      updateCustomPrice,
      updateGlobalCoin,
      navigate,
    ]
  );

  const handleCancelChallengeResponse = useCallback(
    ({ notification, fromId }) => {
      handleNotification(notification);

      const occupiedUsers = users.filter(
        (val) => val._id === user._id || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      if (toast.isActive(toastId)) {
        toast.dismiss(toastId);
        toast.info(notification.message);
      }
    },
    [dispatch, users, user, toastId, handleNotification]
  );

  const handleChallengeDecline = useCallback(
    (notification, fromId) => {
      handleNotificationClick(notification);

      const occupiedUsers = users.filter(
        (val) => val._id === user._id || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      dispatch(
        postEvent({
          eventType: "decline",
          user: user.username,
          targetUser: users.find((val) => val._id === fromId)?.username,
        })
      );

      socket.emit("challenge-decline", {
        message: `${user?.username} has declined the challenge`,
        to: fromId,
        from: user._id,
      });
    },
    [dispatch, users, user]
  );

  const handleChallengeDeclineResponse = useCallback(
    ({ notification }) => {
      handleNotification(notification);
    },
    [handleNotification]
  );

  const handleQuickNotification = useCallback(
    ({
      notification,
      token,
      to,
      from,
      fromId,
      paymentOption,
      scoringOption,
      type,
    }) => {
      playAudio();
      const CustomToast = ({ closeToast }) => (
        <div>
          <p>{notification.message}</p>
          <div className="flex items-center flex-end gap-4">
            <button
              className="bg-green-400 text-white hover:bg-green-500 px-4 py-2 rounded-lg"
              onClick={() => {
                handleQuickResponse(
                  fromId,
                  to,
                  from,
                  paymentOption,
                  scoringOption,
                  notification,
                  token,
                  type
                );
                closeToast();
              }}
            >
              {t("accept")}
            </button>
            <button
              className="bg-green-400 text-white hover:bg-green-500 px-4 py-2 rounded-lg"
              onClick={() => {
                handleChallengeDecline(notification, fromId);
                closeToast();
              }}
            >
              {t("decline")}
            </button>
          </div>
        </div>
      );

      const occupiedUsers = users.filter(
        (val) =>
          val.username.toLowerCase() === to?.toLowerCase() || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "occupied" }));
        });
      }

      setToastId(fromId);
      if (!toast.isActive(fromId)) {
        toast.info(<CustomToast />, {
          autoClose: false,
          closeOnClick: false,
          toastId: fromId,
        });
      }

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
      playAudio();
    },
    [
      dispatch,
      users,
      handleQuickResponse,
      handleChallengeDecline,
      playAudio,
      t,
      handleDesktopNotification,
    ]
  );

  const handleScheduleNotification = useCallback(
    ({ notification }) => {
      if (!toast.isActive("schedule-notification"))
        toast.info(notification.message, { toastId: "schedule-notification" });

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
      console.log("schedule-notification---->>", notification.message);
    },
    [handleDesktopNotification]
  );

  const handleScheduleReply = useCallback(
    ({ fromId, token }) => {
      navigate(`/result/${token}/schedule`);

      window.open(`https://lidarts.org`, "_blank");
    },
    [navigate]
  );

  const handleTopChallengeResponse = useCallback(
    ({ notification }) => {
      if (!toast.isActive("top-notification"))
        toast.info(notification.message, { toastId: "top-notification" });

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);

      // navigate(`/top-challenge-result`);

      // window.open(`https://lidarts.org`, "_blank");
    },
    [handleDesktopNotification]
  );

  const handleStaminaRecovery = useCallback(
    (users) => {
      console.log("stamina-recovery-->>", users);
      if (users.length) {
        const updatedUser = users.find((val) => val._id === user._id);

        if (updatedUser) {
          dispatch(updateStamina(updatedUser.stamina));
        }
      }
    },
    [dispatch, user]
  );

  const handleUpdateArenaMatchResults = useCallback(
    ({ results, winner, player1, player2, date, notification }) => {
      console.log("handle--update--arena-->>>", notification);

      dispatch(
        updateMatchResults({
          player1,
          player2,
          results,
          winner,
          date,
        })
      );

      if (user?.username === winner) {
        playArenaXpAudio();
      }

      toast.info(notification.message);

      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);

      dispatch(fetchUserInfo());
    },
    [dispatch, user, playArenaXpAudio]
  );

  const handleSocketTest = useCallback((data) => {
    console.log("socket--test-->>>", data);
  }, []);

  useEffect(() => {
    dispatch(fetchAllusers());
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    socket.on("user_id", handleUserID);
    socket.on("connect_error", handleErr);

    return () => {
      socket.off("connect_error", handleErr);
      socket.off("user_id", handleUserID);
    };
  }, [user, handleErr, handleUserID]);

  useEffect(() => {}, []);

  // Update users list
  useEffect(() => {
    socket.on("users", updateUsers);
    return () => {
      socket.off("users", updateUsers);
    };
  }, [updateUsers]);

  // Update user status
  useEffect(() => {
    socket.on("statusUpdate", handleUserStatusUpdate);

    return () => {
      socket.off("statusUpdate", handleUserStatusUpdate);
    };
  }, [handleUserStatusUpdate]);

  useEffect(() => {
    socket.on("notifications", handleNotifications);
    return () => {
      socket.off("notifications", handleNotifications);
    };
  }, [handleNotifications]);

  useEffect(() => {
    socket.on("notification", handleNotification);
    return () => {
      socket.off("notification", handleNotification);
    };
  }, [handleNotification]);

  useEffect(() => {
    socket.on("quick-notification", handleQuickNotification);
    return () => {
      socket.off("quick-notification", handleQuickNotification);
    };
  }, [handleQuickNotification]);

  useEffect(() => {
    socket.on("cancel-challenge-response", handleCancelChallengeResponse);

    return () => {
      socket.off("cancel-challenge-response", handleCancelChallengeResponse);
    };
  }, [handleCancelChallengeResponse]);

  useEffect(() => {
    const handleUserConnected = updateUserConnectionStatus(true);
    const handleUserDisconnected = updateUserConnectionStatus(false);

    socket.on("user connected", handleUserConnected);
    socket.on("user disconnected", handleUserDisconnected);
    return () => {
      socket.off("user connected", handleUserConnected);
      socket.off("user disconnected", handleUserDisconnected);
    };
  }, [updateUserConnectionStatus]);

  useEffect(() => {
    socket.on("challenge-decline-response", handleChallengeDeclineResponse);
    return () => {
      socket.off("challenge-decline-response", handleChallengeDeclineResponse);
    };
  }, [handleChallengeDeclineResponse]);

  useEffect(() => {
    socket.on("schedule-notification", handleScheduleNotification);
    return () => {
      socket.off("schedule-notification", handleScheduleNotification);
    };
  }, [handleScheduleNotification]);

  useEffect(() => {
    socket.on("schedule-create-challenge-response", handleScheduleReply);
    return () => {
      socket.off("schedule-create-challenge-response", handleScheduleReply);
    };
  }, [handleScheduleReply]);

  useEffect(() => {
    socket.on("top-challenge-response", handleTopChallengeResponse);
    return () => {
      socket.off("top-challenge-response", handleTopChallengeResponse);
    };
  }, [handleTopChallengeResponse]);

  useEffect(() => {
    socket.on("stamina-recovery", handleStaminaRecovery);
    return () => {
      socket.off("stamina-recovery", handleStaminaRecovery);
    };
  }, [handleStaminaRecovery]);

  useEffect(() => {
    socket.on("arena-match-results", handleUpdateArenaMatchResults);
    return () => {
      socket.off("arena-match-results", handleUpdateArenaMatchResults);
    };
  }, [handleUpdateArenaMatchResults]);

  useEffect(() => {
    socket.on("test", handleSocketTest);
    return () => {
      socket.off("test", handleSocketTest);
    };
  }, [handleSocketTest]);

  useEffect(() => {
    const handleConnect = () => updateUsersStatus("online");
    const handleDisconnect = () => updateUsersStatus("offline");

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [updateUsersStatus]);

  // useEffect(() => {
  //   const handleQuickAcceptResponse = ({ challenger, token }) => {
  //     navigate(
  //       `/scoring/${challenger?.toLowerCase()}/${challenger?.toLowerCase()}/${token}`
  //     );
  //   };

  //   socket.on("quick-own-accept-response", handleQuickAcceptResponse);

  //   return () => {
  //     socket.off("quick-own-accept-response", handleQuickAcceptResponse);
  //   };
  // }, [navigate]);

  useEffect(() => {
    const handleProjectMayhemWeek = (payload) => {
      const { participantsWeek, maxFightUser } = payload;
      console.log("project-mayhem-week-->>>", participantsWeek, maxFightUser);

      if (participantsWeek) {
        if (participantsWeek.includes(user?.username)) {
          if (!toast.isActive("project-mayhem-week"))
            toast.info(
              "Congratulations on achieving 500 fights in a week! Your participation has earned you 250 XP!",
              {
                toastId: "project-mayhem-week",
              }
            );

          if (maxFightUser?._id === user?._username) {
            if (!toast.isActive("project-mayhem-week-highest"))
              toast.info(
                "Congratulations on achieving the highest number of fights in a week! You earned 'Project Mayhem Week' achievement!",
                {
                  toastId: "project-mayhem-week-highest",
                }
              );
          }
        } else {
          if (!toast.isActive("project-mayhem-week"))
            toast.info("Congratulations on achieving 500 fights in a week!", {
              toastId: "project-mayhem-week",
            });
        }
      }
    };

    socket.on("project-mayhem-week", handleProjectMayhemWeek);

    return () => {
      socket.off("project-mayhem-week", handleProjectMayhemWeek);
    };
  }, [user]);

  useEffect(() => {
    const handleUndergroundChampion = (payload) => {
      const { participants, maxFightUser } = payload;
      console.log("underground-champion-->>>", participants, maxFightUser);

      if (participants) {
        if (participants.includes(user?.username)) {
          if (!toast.isActive("underground-champion"))
            toast.info(
              "Congratulations on achieving 100 fights in 24 hours! Your participation has earned you 100 XP!",
              {
                toastId: "underground-champion",
              }
            );

          if (maxFightUser?._id === user?._username) {
            if (!toast.isActive("underground-champion-highest"))
              toast.info(
                "Congratulations on achieving the highest number of fights in a week! You earned 'The Underground Champion' achievement!",
                {
                  toastId: "underground-champion-highest",
                }
              );
          }
        } else {
          if (!toast.isActive("underground-champion"))
            toast.info(
              "Congratulations on achieving 100 fights in a 24 hours!",
              {
                toastId: "underground-champion",
              }
            );
        }
      }
    };

    socket.on("underground-champion", handleUndergroundChampion);

    return () => {
      socket.off("underground-champion", handleUndergroundChampion);
    };
  }, [user]);

  useEffect(() => {
    const handleJacksVictory = ({ username }) => {
      console.log("jacks-victory-->>>", username);

      if (!toast.isActive("jacks-victory"))
        toast.info(
          `Congratulations! ${username} has achieved 10 wins in 48 hours!`,
          {
            toastId: "jacks-victory",
          }
        );
    };

    socket.on("jacks-victory", handleJacksVictory);

    return () => {
      socket.off("jacks-victory", handleJacksVictory);
    };
  }, []);

  const handleAllNotificationsRead = () => {
    // Mark all notifications as read
    socket.emit("allNotificationsRead");

    // Remove all notifications from the state
    setNotifications([]);
  };

  const handleNotificationClick = (notification) => {
    // Mark the notification as read
    socket.emit("notificationRead", notification._id);

    // Remove the notification from the state
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n._id !== notification._id)
    );
  };

  return {
    users,
    notifications,
    handleAllNotificationsRead,
    handleNotificationClick,
  };
};
