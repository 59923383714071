import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ScoringKeyPad from "./ScoringKeyPad";

const ScoringInputBoard = ({
  turn,
  isUndo,
  isEnableKeyEvent,
  isFinished,
  handleSubmit,
  handleGameFinish,
  handleUndo,
  onClickUndo,
}) => {
  const { t } = useTranslation();
  const [score, setScore] = useState("");
  const [number, setNumber] = useState("");

  const handleNumberClick = useCallback((num) => {
    console.log("event.key", num);

    setNumber((prevNumber) => prevNumber + num);
  }, []);

  const handleEmpty = useCallback(() => {
    setNumber("");
    setScore("");
  }, []);

  const handleCorrectScore = useCallback(
    (score) => {
      handleUndo(score);
      // onClickUndo();
      handleEmpty();
    },
    [handleUndo, handleEmpty]
  );

  const onSubmit = useCallback(
    (evt) => {
      evt && evt.preventDefault();
      if (isUndo) {
        handleCorrectScore(score);
      } else {
        handleSubmit(score);
        handleEmpty();
      }
    },
    [score, isUndo, handleSubmit, handleCorrectScore, handleEmpty]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (isEnableKeyEvent) {
        if (event.key === "Enter") {
          event.preventDefault();
          onSubmit();
        } else if (!isNaN(event.key) && event.key !== " ") {
          handleNumberClick(event.key);
        } else if (event.key === "Backspace") {
          setNumber((prevNumber) => prevNumber.slice(0, -1));
        }
      }
    },
    [isEnableKeyEvent, onSubmit, handleNumberClick]
  );

  const handleChange = (e) => {
    e.preventDefault();
    const newScore = e.target.value;
    setScore(newScore);
    setNumber(newScore.toString());
  };

  useEffect(() => {
    if (number.length > 0) {
      setScore(Number(number));
    } else {
      setScore("");
    }
  }, [number]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="relative flex flex-col gap-1 sm:gap-4 z-10">
      <input
        type="number"
        maxLength={4}
        value={score}
        onChange={handleChange}
        disabled={isFinished || !turn}
        className="text-xl dark:bg-gray-900 dark:text-white px-4 py-2 rounded-md border-none focus:outline-none"
      />
      {isFinished ? (
        <button
          className="px-4 py-2 border rounded-md font-semibold text-white hover:border-gray-400"
          onClick={handleGameFinish}
        >
          {t("gameOverview")}
        </button>
      ) : (
        <button
          className={`px-4 py-2 border rounded-md font-semibold ${
            isUndo
              ? "text-white bg-orange-600"
              : "text-black dark:text-white sm:text-white bg-white dark:bg-gray-900 sm:bg-transparent"
          } hover:border-gray-400`}
          disabled={!turn || isFinished}
          onClick={onClickUndo}
        >
          {isUndo ? t("enterCorrectScore") : t("undoLastScore")}
        </button>
      )}
      <div>
        <ScoringKeyPad
          handleNumberClick={handleNumberClick}
          handleEmpty={handleEmpty}
          handleSubmit={onSubmit}
          turn={turn}
          isFinished={isFinished}
        />
      </div>
    </div>
  );
};

export default ScoringInputBoard;
