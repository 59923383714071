import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import http from "../../helper/http-client";

export const useOpenGamesApi = () => {
  const [openGames, setOpenGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchOpenGames = async () => {
      setIsLoading(true);
      try {
        const response = await http.get(
          `/score/get/open-games/${user?.username}`
        );
        setOpenGames(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOpenGames();
  }, [user?.username]);

  return {
    openGames,
    isLoading,
  };
};
