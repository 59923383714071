import {
  HomeIcon,
  AdjustmentsVerticalIcon,
  InformationCircleIcon,
  IdentificationIcon,
  CalendarDaysIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";

const quotes = [
  {
    origin: "Alles was du besitzt, besitzt irgendwann dich.",
    additive: "Alles was du nicht checkst, checkt irgendwann dich",
  },
  {
    origin:
      "Von dem Geld, das wir nicht haben, kaufen wir Dinge, die wir nicht brauchen, um Leuten zu imponieren, die wir nicht mögen.",
    additive:
      "Von dem Geld, das wir nicht haben, kaufen wir Dinge, die wir nicht brauchen, um ein Niveau zu erreichen, das niemandem imponiert.",
  },
  {
    origin:
      "Wir sind Konsumenten. Wir sind Abfallprodukte der allgemeinen Lifestyle-Obsessionen!",
    additive:
      "Wir sind Dartspieler. Wir sind Abfallprodukte der allgemeinen Leistungssport-Obsessionen!",
  },
  {
    origin: "Wenn Menschen denken, dass du stirbst, hören sie dir richtig zu.",
    additive:
      "Wenn Menschen denken, dass du checkst, passen sie erst richtig auf.",
  },
  {
    origin:
      "Zuerst musst du wissen, nicht fürchten, sondern wissen, dass du einmal sterben wirst.",
    additive:
      "Zuerst musst du wissen, nicht fürchten, wissen, dass du einmal das große Singlefeld verpassen wirst.",
  },
  {
    origin:
      "Erst nachdem wir alles verloren haben, haben wir die Freiheit, alles zu tun.",
    additive:
      "Erst nachdem wir das Spiel verloren haben, haben wir die Freiheit, alles zu checken",
  },
  {
    origin:
      "Kleine Benimmfrage: Wenn ich vorbei gehe… Wende ich Ihnen den Arsch oder den Schritt zu?",
    additive:
      "Kleine Benimmfrage: Wenn ich zum Handshake gehe, habe ich da meine Darts schon geholt oder stecken die noch?",
  },
  {
    origin: "Ich will, dass du mich schlägst, so hart wie du nur kannst!",
    additive: "Ich will, dass du scorest, so hart wie du nur kannst",
  },
  {
    origin:
      "Wir wurden durch das Fernsehen aufgezogen in dem Glauben, dass wir alle irgendwann mal Millionäre werden, Filmgötter, Rockstars.",
    additive:
      "Wir wurden durch das Fernsehen aufgezogen in dem Glauben, dass wir alle irgendwann mal Millionäre werden, Filmgötter, Rockstars.",
  },
  {
    origin:
      "Werden wir aber nicht! Und das wird uns langsam klar! Und wir sind kurz, ganz kurz vorm Ausrasten.",
    additive:
      "Werden wir aber nicht! Und das wird uns langsam klar! Und wir sind kurz, ganz kurz vorm Ausrasten.",
  },
  {
    origin:
      "Wir sind die Zweitgeborenen der Geschichte, Leute. Männer ohne Zweck, ohne Ziel.",
    additive:
      "Wir sind die Zweitgeborenen der Geschichte, Leute. Menschen ohne Zweck, ohne Ziel.",
  },
  {
    origin:
      "Wir haben keinen großen Krieg, keine große Depression. Unser großer Krieg ist kein spiritueller, unsere große Depression ist unser Leben.",
    additive:
      "Wir haben keinen großen Krieg, keine große Depression. Unser großer Krieg ist ein mentaler, unsere große Depression ist die doppel 1.",
  },
  {
    origin: "Du hast mich in einer seltsamen Phase meines Lebens getroffen…",
    additive:
      "Darts hat mich in einer seltsamen Phase meines Lebens getroffen…",
  },
  {
    origin: "Willkommen im Fight Club!",
    additive: "Willkommen im Darts Fight Club!",
  },
  {
    origin:
      "Die erste Regel des Fight Club lautet: Ihr verliert kein Wort über den Fight Club!",
    additive:
      "Die erste Regel des Fight DartsClub lautet: Ihr verliert ab und zu mal ein Wort über den Darts Fight Club.",
  },
  {
    origin:
      "Die zweite Regel des Fight Club lautet: Ihr verliert kein Wort über den Fight Club!",
    additive:
      "Die zweite Regel des Fight Club lautet: Ihr verliert ab und zu mal EIN WORT über den Darts Fight Club.",
  },
];

const navigation = [
  {
    name: "Home",
    to: "/",
    current: "/home",
    icon: <HomeIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Pyramid",
    to: "/pyramid",
    current: "/pyramid",
    icon: <AdjustmentsVerticalIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Infos",
    to: "/infos",
    current: "/infos",
    icon: <InformationCircleIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Profile",
    to: "/profile-summary",
    current: "/profile",
    icon: <IdentificationIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Calendar",
    to: "/schedule",
    current: "/calendar",
    icon: <CalendarDaysIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Events",
    to: "/events",
    current: "/events",
    icon: <CalendarIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Ranking",
    to: "/ranking-table",
    current: "/ranking",
    icon: <AdjustmentsVerticalIcon className="h-4 w-4" aria-hidden="true" />,
  },
];

const finishes = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129,
  130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144,
  145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 160,
  161, 164, 167, 170,
];

const impossibleNumbers = [179, 178, 176, 175, 173, 172, 169];

const userMenuItems = [
  { name: "Your Profile", to: "/profile-summary" },
  { name: "Settings", to: "/settings" },
];

const languageList = [
  { label: "Deutsch", value: "de" },
  { label: "English", value: "en" },
];

const Animationcontainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.5,
    },
  },
};

const SubAnimationcontainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.5,
    },
  },
};

const TopDownAnimationitem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const RLAnimationitem = {
  hidden: { x: 20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const LRAnimationitem = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Rows per page",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

// const URL = "http://localhost:4000";
const URL = 'https://backend.dartsfightclub.de';

const constant = {
  quotes,
  finishes,
  impossibleNumbers,
  navigation,
  userMenuItems,
  Animationcontainer,
  SubAnimationcontainer,
  TopDownAnimationitem,
  RLAnimationitem,
  LRAnimationitem,
  languageList,
  paginationComponentOptions,
  URL,
};

export default constant;
