import { motion } from "framer-motion";
import { useConstant } from "../hooks/useConstant";
import constant from "../helper/constant";

const Rules = () => {
  const { additiveTexts } = useConstant();

  return (
    <motion.ul
      variants={constant.Animationcontainer}
      initial="hidden"
      animate="visible"
      className="space-y-3"
    >
      {additiveTexts.map((item, index) => (
        <motion.li
          variants={constant.TopDownAnimationitem}
          key={index}
          className="overflow-hidden text-left rounded-md bg-white px-6 py-4 shadow dark:bg-gray-900 dark:text-gray-100"
        >
          {item}
        </motion.li>
      ))}
    </motion.ul>
  );
};

export default Rules;
