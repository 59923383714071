import { useOpenGamesApi } from "../hooks/OpenGames/useOpenGamesApi";
import { useHandleOpenGames } from "../hooks/OpenGames/useHandleOpenGames";
import Loading from "../components/Loading";

const OpenGamesPage = () => {
  const { openGames, isLoading } = useOpenGamesApi();
  const { handleJoinGame } = useHandleOpenGames();

  const convertDate = (date) => {
    const newDate = new Date(date);
    return newDate.toDateString() + " " + newDate.toTimeString();
  };
  return (
    <div className="mt-12 p-4">
      <h1 className="text-4xl font-bebas-neue tracking-widest font-semibold mb-4">
        Games in progress
      </h1>
      <div></div>
      {isLoading && <Loading />}
      <ul className="space-y-4">
        {openGames
          ?.sort((a, b) => {
            return new Date(b?.createdAt) - new Date(a?.createdAt);
          })
          ?.map((game) => (
            <li key={game?._id} className="grid grid-cols-3 gap-8">
              <h2 className="text-xl font-semibold col-span-1">
                {game?.p1?.name} : {game?.p2?.name}
              </h2>
              <p className="col-span-1">{convertDate(game?.createdAt)}</p>
              <button
                className="bg-blue-500 text-white px-4 py-2 col-span-1 rounded"
                onClick={() => handleJoinGame(game)}
              >
                Join Game
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default OpenGamesPage;
