import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import http from "../../helper/http-client";
import socket from "../../socket";
import constant from "../../helper/constant";
import { determineTurn } from "../../helper/scoring";
import {
  handleBullScoreSocketEvent,
  handleScoreUpdateSocketEvent,
  handleFinishSocketEvent,
} from "../../socket/scoringSocketEvent";

const useScoringSystem = (opponent, challenger, token) => {
  const navigate = useNavigate();
  const { allUsers, user } = useSelector((state) => state.auth);
  const [matchStatus, setMatchStatus] = useState(null);
  const [userCurrentScore, setUserCurrentScore] = useState(501);
  const [opponentCurrentScore, setOpponentCurrentScore] = useState(501);
  const [userScoreHistory, setUserScoreHistory] = useState([]);
  const [opponentScoreHistory, setOpponentScoreHistory] = useState([]);
  const [doubleMissed, setDoubleMissed] = useState(0);
  const [inputScore, setInputScore] = useState(0);
  const [userWin, setUserWin] = useState(0);
  const [opponentWin, setOpponentWin] = useState(0);
  const [legNo, setLegNo] = useState(1);
  const [userTurn, setUserTurn] = useState(true);
  const [modalType, setModalType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBullModalOpen, setIsBullModalOpen] = useState(false);
  const [isFinishModal, setIsFinishModal] = useState(false);
  const [isBackModal, setIsBackModal] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isUndo, setIsUndo] = useState(false);
  const [isBullModalWaiting, setIsBullModalWaiting] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const { impossibleNumbers } = constant;

  // check if the keyboard event is enabled
  const isEnableKeyEvent = useMemo(() => {
    if (
      !isFinished &&
      !isModalOpen &&
      !isFinishModal &&
      !isBackModal &&
      !isBullModalOpen &&
      !isBullModalWaiting &&
      userTurn
    )
      return true;
    else return false;
  }, [
    isFinished,
    isFinishModal,
    isModalOpen,
    isBackModal,
    isBullModalOpen,
    isBullModalWaiting,
    userTurn,
  ]);

  // determin opponent user
  const opponentUser = useMemo(
    () => allUsers.find((user) => user?.username?.toLowerCase() === opponent),
    [allUsers, opponent]
  );

  // -----------------------------------Socket Events-----------------------------------------------
  // socket event for bull score
  const handleBullScoreSubmit = useCallback(
    async (score) => {
      setIsBullModalWaiting(true);
      handleBullScoreSocketEvent(score, token, user, opponentUser);
    },
    [token, opponentUser, user]
  );

  // -----------------------------------Handlers----------------------------------------
  // toggle keyboard visible on mobile screen
  const onChangeKeyboardVisible = useCallback(() => {
    setIsKeyboardVisible((prev) => !prev);
  }, []);

  const updateUserScoreHistory = (score, legNo) => {
    setUserScoreHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      if (!newHistory[legNo - 1]) {
        newHistory[legNo - 1] = [];
      }
      newHistory[legNo - 1] = [...newHistory[legNo - 1], score];
      return newHistory;
    });
  };

  const resetScores = useCallback(() => {
    setUserCurrentScore(501);
    setOpponentCurrentScore(501);
    setUserScoreHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[legNo] = [];
      return newHistory;
    });
    setOpponentScoreHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[legNo] = [];
      return newHistory;
    });
  }, [legNo]);

  const handleSubmit = useCallback(
    (score) => {
      if (!(score >= 0 && score <= 180) || impossibleNumbers.includes(score)) {
        toast.error(
          "Score should be between 0 and 180 and not an impossible number. Please try again."
        );
        return;
      }
      setIsKeyboardVisible(false); // Hide the keyboard after submitting the score in mobile screen
      setInputScore(score);
      if (userTurn) {
        // player checkout
        if (userCurrentScore - score === 0) {
          setIsModalOpen(true);
          setModalType("doubleMissed");
          return;
        } else if (userCurrentScore - score < 0 || score === 0) {
          setIsModalOpen(true);
          setInputScore(0);
          setModalType("bustThrown");
          return;
        } else {
          if (
            (userCurrentScore - score > 50 && score > 0) ||
            userCurrentScore > 170
          ) {
            handleScoreUpdateSocketEvent(
              score,
              0,
              3,
              0,
              token,
              isUndo,
              user,
              opponentUser
            );
            setUserCurrentScore((prevScore) => prevScore - score);
            updateUserScoreHistory(score, legNo);
            setUserTurn(false);
            setIsUndo(false);
          } else {
            setIsModalOpen(true);
            setModalType("doubleMissed");
          }
          return;
        }
      }
    },
    [
      impossibleNumbers,
      userCurrentScore,
      userTurn,
      user,
      isUndo,
      opponentUser,
      legNo,
      token,
    ]
  );

  const handleUndo = useCallback(
    async (score) => {
      if (!(score >= 0 && score <= 180) || impossibleNumbers.includes(score)) {
        toast.error(
          "Score should be between 0 and 180 and not an impossible number. Please try again."
        );
        return;
      }
      setInputScore(score);
      if (userTurn) {
        const lastIdx = userScoreHistory[legNo - 1].length - 1;
        const lastScore = userScoreHistory[legNo - 1][lastIdx];

        // player checkout
        if (userCurrentScore + lastScore - score === 0) {
          setIsModalOpen(true);
          setModalType("doubleMissed");
          return;
        } else if (userCurrentScore + lastScore - score < 0 || score === 0) {
          setIsModalOpen(true);
          setInputScore(0);
          setModalType("bustThrown");
          return;
        } else {
          if (
            (userCurrentScore + lastScore - score > 50 && score > 0) ||
            userCurrentScore + lastScore > 170
          ) {
            handleScoreUpdateSocketEvent(
              score,
              0,
              3,
              0,
              token,
              isUndo,
              user,
              opponentUser
            );
            setUserCurrentScore((prevScore) => prevScore + lastScore - score);
            setUserScoreHistory((prevHistory) => {
              const newHistory = [...prevHistory];
              newHistory[legNo - 1][lastIdx] = score;
              return newHistory;
            });
            setIsUndo(false);
          } else {
            setIsModalOpen(true);
            setModalType("doubleMissed");
          }
          return;
        }
      }
    },
    [
      impossibleNumbers,
      userScoreHistory,
      legNo,
      userTurn,
      isUndo,
      user,
      opponentUser,
      token,
      userCurrentScore,
    ]
  );

  const handleSubmitDouble = useCallback(
    async (double) => {
      setDoubleMissed(double);
      setIsModalOpen(false);
      if (isUndo) {
        const lastIdx = userScoreHistory[legNo - 1].length - 1;
        const lastScore = userScoreHistory[legNo - 1][lastIdx];

        if (userCurrentScore + lastScore - inputScore === 0) {
          setIsModalOpen(true);
          setModalType("checkoutThrown");
        } else {
          handleScoreUpdateSocketEvent(
            inputScore,
            double,
            3,
            0,
            token,
            isUndo,
            user,
            opponentUser
          );
          setUserCurrentScore(
            (prevScore) => prevScore + lastScore - inputScore
          );
          setUserScoreHistory((prevHistory) => {
            const newHistory = [...prevHistory];
            newHistory[legNo - 1][lastIdx] = inputScore;
            return newHistory;
          });
          setIsUndo(false);
        }
      } else {
        if (userCurrentScore - inputScore === 0) {
          setIsModalOpen(true);
          setModalType("checkoutThrown");
        } else {
          handleScoreUpdateSocketEvent(
            inputScore,
            double,
            3,
            0,
            token,
            isUndo,
            user,
            opponentUser
          );
          setUserCurrentScore((prevScore) => prevScore - inputScore);
          updateUserScoreHistory(inputScore, legNo);
          setUserTurn(false);
        }
      }
    },
    [
      inputScore,
      isUndo,
      legNo,
      userCurrentScore,
      userScoreHistory,
      opponentUser,
      token,
      user,
    ]
  );

  const handleSubmitCheckout = useCallback(
    (checkout) => {
      if (
        legNo + 1 > 5 ||
        (legNo > 2 && Math.abs(userWin + 1 - opponentWin) >= 2)
      ) {
        handleFinishSocketEvent(token, opponentUser);
        setIsFinished(true);
      }
      resetScores();
      setUserTurn(determineTurn(matchStatus, user, legNo));
      setUserWin((prevWin) => prevWin + 1);
      setLegNo((prevLeg) => prevLeg + 1);
      handleScoreUpdateSocketEvent(
        inputScore,
        doubleMissed,
        3,
        checkout,
        token,
        isUndo,
        user,
        opponentUser
      );
      setIsModalOpen(false);
      setIsUndo(false);
    },
    [
      inputScore,
      legNo,
      opponentUser,
      token,
      user,
      doubleMissed,
      matchStatus,
      userWin,
      isUndo,
      opponentWin,
      resetScores,
    ]
  );

  const handleSumitBust = useCallback(
    (bust) => {
      if (isUndo) {
        const lastIdx = userScoreHistory[legNo - 1].length - 1;
        const lastScore = userScoreHistory[legNo - 1][lastIdx];
        setUserCurrentScore((prevScore) => prevScore + lastScore);
        setUserScoreHistory((prevHistory) => {
          const newHistory = [...prevHistory];
          newHistory[legNo - 1][lastIdx] = 0;
          return newHistory;
        });
      } else {
        setUserTurn(false);
        updateUserScoreHistory(0, legNo);
      }
      handleScoreUpdateSocketEvent(
        0,
        0,
        Number(bust),
        0,
        token,
        isUndo,
        user,
        opponentUser
      );
      setIsUndo(false);
      setIsModalOpen(false);
    },
    [legNo, opponentUser, userScoreHistory, token, user, isUndo]
  );

  const handleSubmitModal = useCallback(
    (score) => {
      switch (modalType) {
        case "doubleMissed":
          handleSubmitDouble(score);
          break;
        case "checkoutThrown":
          handleSubmitCheckout(score);
          break;
        case "bustThrown":
          handleSumitBust(score);
          break;
        default:
          setIsModalOpen(false);
          break;
      }
    },
    [handleSubmitDouble, handleSubmitCheckout, handleSumitBust, modalType]
  );

  const handleGameFinish = () => {
    navigate(`/scoring-result/${token}`);
  };

  const handleFinishClick = async () => {
    try {
      setIsFinished(true);
      setIsFinishModal(false);
      await http.post("/result/finish-match", { token });
      socket.emit("match-finish", { token, opponentId: opponentUser._id });
      await fetchUserScore();
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleScoreUpdate = useCallback(
    ({ updatedMatch }) => {
      if (updatedMatch.legNo === legNo + 1) {
        setLegNo((prevLeg) => prevLeg + 1);
        resetScores();
        if (user.username === updatedMatch.p1.name) {
          setUserWin(updatedMatch.p1.legs_won);
          setOpponentWin(updatedMatch.p2.legs_won);
        } else {
          setUserWin(updatedMatch.p2.legs_won);
          setOpponentWin(updatedMatch.p1.legs_won);
        }
      }

      if (user.username === updatedMatch.p1.name) {
        setOpponentCurrentScore(updatedMatch.p2.currentScore);
        setOpponentScoreHistory(
          updatedMatch.p2.scoreHistory.map((score) => score?.scores)
        );
        setUserTurn(updatedMatch.challengerTurn);
      } else {
        setOpponentCurrentScore(updatedMatch.p1.currentScore);
        setOpponentScoreHistory(
          updatedMatch.p1.scoreHistory.map((score) => score?.scores)
        );
        setUserTurn(!updatedMatch.challengerTurn);
      }
    },
    [user, legNo, resetScores]
  );

  const fetchUserScore = useCallback(async () => {
    try {
      const { data } = await http.get(`/score/get/match-status/${token}`);
      console.log("match-status--->>>", data);
      if (data) {
        setMatchStatus(data);
        setLegNo(data.legNo);
        setIsFinished(data.isFinished);
        // setIsBullModalOpen(data.bullModal);
        if (user.username === data.p1?.name) {
          setUserCurrentScore(data.p1.currentScore);
          setOpponentCurrentScore(data.p2.currentScore);
          setUserScoreHistory(
            data.p1.scoreHistory.map((score) => score?.scores)
          );
          setOpponentScoreHistory(
            data.p2.scoreHistory.map((score) => score?.scores)
          );
          setUserWin(data.p1.legs_won);
          setOpponentWin(data.p2.legs_won);
          setUserTurn(data.challengerTurn);
          setIsBullModalOpen(!data.p1.bull.isClosed);
          setIsBullModalWaiting(data.p1.bull.isWaiting);
        } else {
          setUserCurrentScore(data.p2.currentScore);
          setOpponentCurrentScore(data.p1.currentScore);
          setUserScoreHistory(
            data.p2.scoreHistory.map((score) => score?.scores)
          );
          setOpponentScoreHistory(
            data.p1.scoreHistory.map((score) => score?.scores)
          );
          setUserWin(data.p2.legs_won);
          setOpponentWin(data.p1.legs_won);
          setUserTurn(!data.challengerTurn);
          setIsBullModalOpen(!data.p2.bull.isClosed);
          setIsBullModalWaiting(data.p2.bull.isWaiting);
        }
      } else {
        setUserTurn(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [token, user.username]);

  const handleFinish = useCallback(() => {
    // navigate(`/result/${token}/quick`);
    fetchUserScore();
    setIsFinished(true);
  }, [fetchUserScore]);

  const handleBullScore = useCallback(
    (match) => {
      setMatchStatus(match);
      if (user.username === match.p1?.name) {
        setUserTurn(match.challengerTurn);
        setIsBullModalOpen(!match.p1.bull.isClosed);
        setIsBullModalWaiting(match.p1.bull.isWaiting);
      } else {
        setUserTurn(!match.challengerTurn);
        setIsBullModalOpen(!match.p2.bull.isClosed);
        setIsBullModalWaiting(match.p2.bull.isWaiting);
      }
    },
    [user.username]
  );

  const onClickUndo = useCallback(() => {
    setIsUndo((prev) => !prev);
  }, []);

  useEffect(() => {
    socket.on("bull-score-response", handleBullScore);
    return () => {
      socket.off("bull-score-response", handleBullScore);
    };
  }, [handleBullScore]);

  useEffect(() => {
    socket.on("score-update-response", handleScoreUpdate);
    socket.on("finish-match", handleFinish);
    return () => {
      socket.off("score-update-response", handleScoreUpdate);
      socket.off("finish-match", handleFinish);
    };
  }, [handleScoreUpdate, handleFinish]);

  useEffect(() => {
    setTimeout(() => {
      fetchUserScore();
    }, 2000);
  }, []);

  return {
    opponentUser,
    user,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    modalType,
    isModalOpen,
    isFinished,
    isBackModal,
    isFinishModal,
    isBullModalOpen,
    isBullModalWaiting,
    isKeyboardVisible,
    isEnableKeyEvent,
    isUndo,
    fetchUserScore,
    handleSubmitDouble,
    handleSubmit,
    handleGameFinish,
    handleFinishClick,
    handleBack,
    setIsBackModal,
    setIsFinishModal,
    setIsBullModalOpen,
    setIsBullModalWaiting,
    setIsModalOpen,
    handleBullScoreSubmit,
    handleSubmitModal,
    handleUndo,
    onChangeKeyboardVisible,
    onClickUndo,
  };
};

export default useScoringSystem;
