import { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "./CustomModal";

const PriceModal = ({
  isOpenModal,
  type = "quick",
  challenger,
  receiver,
  onCloseModal,
  sendChallenge,
  sendQuickNotification,
}) => {
  const { t } = useTranslation();

  const [priceOption, setPriceOption] = useState("payAll");
  const [scoringOption, setScoringOption] = useState("lidarts");

  const handlePriceOptionChange = (e) => {
    setPriceOption(e.target.value);
  };

  const handleScoringOptionChange = (e) => {
    setScoringOption(e.target.value);
  };

  const handlePriceOption = () => {
    sendQuickNotification(receiver, challenger, priceOption, scoringOption, type);
    sendChallenge();
    onCloseModal();
  };

  return (
    <CustomModal
      title={t("priceModalMsg")}
      isOpen={isOpenModal}
      onClose={onCloseModal}
    >
      <div className="bg-white dark:bg-gray-800 text-black dark:text-white pt-8">
        <div className="relative mb-4 flex flex-col gap-2">
          <p className="text-lg font-bold">Payment Option</p>
          <div className="flex gap-4 border p-2 rounded-md">
            <div className="flex-1">
              <label className="flex items-center text-md">
                <input
                  type="radio"
                  name="paymentOption"
                  value="payAll"
                  checked={priceOption === "payAll"}
                  onChange={handlePriceOptionChange}
                  className="mr-2"
                />
                {t("payAll")}
              </label>
              {/* <p className="text-xs pl-6 mt-2 opacity-80">
              {t("payAllDescription")}
            </p> */}
            </div>
            <div className="flex-1">
              <label className="flex items-center text-md">
                <input
                  type="radio"
                  name="paymentOption"
                  value="payHalf"
                  checked={priceOption === "payHalf"}
                  onChange={handlePriceOptionChange}
                  className="mr-2"
                />
                50 : 50
              </label>
              {/* <p className="text-xs pl-6 mt-2 opacity-80">
              {t("50-50-description")}
            </p> */}
            </div>
          </div>
        </div>
        <div className="mb-4 flex flex-col gap-2">
          <p className="text-lg font-bold">Scoring Option</p>
          <div className="flex gap-4 border p-2 rounded-md">
            <div className="flex-1">
              <label className="flex items-center text-md">
                <input
                  type="radio"
                  name="scoringOption"
                  value="lidarts"
                  checked={scoringOption === "lidarts"}
                  onChange={handleScoringOptionChange}
                  className="mr-2"
                />
                Lidarts Scoring
              </label>
              {/* <p className="text-xs pl-6 mt-2 opacity-80">
              {t("payAllDescription")}
            </p> */}
            </div>
            <div className="flex-1">
              <label className="flex items-center text-md">
                <input
                  type="radio"
                  name="scoringOption"
                  value="own"
                  checked={scoringOption === "own"}
                  onChange={handleScoringOptionChange}
                  className="mr-2"
                />
                FC Scoring
              </label>
              {/* <p className="text-xs pl-6 mt-2 opacity-80">
              {t("50-50-description")}
            </p> */}
            </div>
          </div>
        </div>
        <div className="flex mt-4">
          <button
            className="bg-green-400 dark:bg-gray-900 text-white rounded-md py-2 px-4 hover:bg-gray-500 dark:hover:bg-gray-700 ml-auto"
            onClick={handlePriceOption}
          >
            {t("sendChallenge")}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default PriceModal;
