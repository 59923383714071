const ResultScores = ({ p1_name, p2_name, p1_scores, p2_scores }) => {
  return (
    <div className="overflow-x-auto dark:text-white">
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b uppercase">{p1_name} Scores</th>
            <th className="py-2 px-4 border-b uppercase">{p2_name} Scores</th>
          </tr>
        </thead>
        <tbody>
          {p1_scores.map((p1_item, index) => {
            const p2_item = p2_scores[index];
            return (
              <tr key={p1_item._id}>
                <td
                  className={`py-2 px-4 border-b ${
                    p1_item.to_finish ? "bg-yellow-200 text-black" : ""
                  }`}
                >
                  {p1_item.scores.join(", ")}
                </td>
                <td
                  className={`py-2 px-4 border-b ${
                    p2_item && p2_item.to_finish ? "bg-yellow-200 text-black" : ""
                  }`}
                >
                  {p2_item ? p2_item.scores.join(", ") : "-"}
                </td>
              </tr>
            );
          })}
          {p2_scores.slice(p1_scores.length).map((p2_item) => (
            <tr key={p2_item._id}>
              <td className="py-2 px-4 border-b">-</td>
              <td
                className={`py-2 px-4 border-b ${
                  p2_item.to_finish ? "bg-yellow-200 text-black" : ""
                }`}
              >
                {p2_item.scores.join(", ")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultScores;
