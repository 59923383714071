import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import CustomModal from "./CustomModal";

const ConfirmModal = ({
  isOpen,
  modalType,
  content,
  onClose,
  handleConfirm,
}) => {
  const modalTitle = modalType === "warning" ? "Warning!" : "Are you sure?";
  const buttonClass =
    modalType === "warning"
      ? "bg-red-500 hover:bg-red-700"
      : "bg-blue-500 hover:bg-blue-700";
  const Icon =
    modalType === "warning" ? ExclamationTriangleIcon : CheckCircleIcon;
  const iconColor = modalType === "warning" ? "text-red-500" : "text-blue-500";

  return (
    <CustomModal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <div className="flex items-center mt-4">
        <Icon className={`h-6 w-6 mr-2 ${iconColor}`} />
        <p className="text-gray-700">{content}</p>
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleConfirm}
          className={`px-4 py-2 text-white rounded ${buttonClass}`}
        >
          OK
        </button>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
