import Loading from "../Loading";
import { ownTopBriefResult } from "../../helper/result";
import { useTranslation } from "react-i18next";

const ResultBoard = ({ data1, data2, kind }) => {
  return (
    <div className="flex text-white py-2">
      <p className="font-semibold w-1/3">{data1 || 0}</p>
      <p className="w-1/3">{kind}</p>
      <p className="font-semibold w-1/3">{data2 || 0}</p>
    </div>
  );
};

const OwnFightResult = ({ isLoading, result }) => {
  const { t } = useTranslation();
  return (
    <div>
      {isLoading ? (
        <div className="mt-12">
          <div className="my-6">
            <Loading />
          </div>
          <div className="my-6">
            <Loading />
          </div>
          <div className="my-6">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="bg-gradient-to-tl bg-green-500 dark:from-dark-card-start dark:to-dark-card-end p-4 my-4 rounded-lg">
            <p className="font-normal text-white text-lg p-2">
              <span className="font-semibold">{t("matchStart: ")}</span>
              {new Date(result?.date).toLocaleString()}
            </p>
            {ownTopBriefResult(result)?.map((item, index) => (
              <ResultBoard
                key={index}
                data1={item.data1}
                data2={item.data2}
                kind={item.title}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnFightResult;