import { useState } from "react";
import CustomModal from "../CustomModal";
import SpinnerLoading from "../SpinnerLoading";

const BullsModal = ({ isOpen, isWaiting, onClose, handleSubmit }) => {
  const [formData, setFormData] = useState(0);

  const onChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmitBulls = () => {
    if (formData < 0 || formData > 180) {
      setFormData(0);
      return;
    }
    handleSubmit(formData);
    setFormData(0);
  };

  return (
    <CustomModal title="Bull Check" isOpen={isOpen} onClose={() => {}}>
      <div className="flex flex-col gap-4 mt-4">
        <div>
          <label className="text-lg font-semibold" htmlFor="bull">
            Bull Score
          </label>
          <input
            type="number"
            id="bull"
            name="bull"
            className="w-full p-2 border border-gray-300 rounded-md"
            value={formData}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        {isWaiting ? (
          <SpinnerLoading />
        ) : (
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-semibold px-4 py-2 rounded-md"
            onClick={handleSubmitBulls}
          >
            Submit
          </button>
        )}
      </div>
    </CustomModal>
  );
};

export default BullsModal;
