import UserAvatar from "./UserAvatar";
import AchievementModalBoard from "./AchievementModalBoard";
import images from "../../helper/images";

const ResultAchievements = ({ winUser, loseUser }) => {
  return (
    <div className="flex flex-wrap gap-4 z-50 p-4">
      <div className="w-full sm:w-48p">
        <div className="relative">
          <UserAvatar avatar={winUser?.init?.avatar} name={winUser?.name} />
          <img
            className="absolute top-0 w-8 h-8"
            src={images.FIRSTMEDAL}
            alt="first-medal"
          />
          <img
            className="absolute bottom-0 w-8 h-8"
            src={images.TROPHY}
            alt="first-medal"
          />
          <img
            className="absolute -top-4 left-1/2 -translate-x-1/2 w-8 h-8"
            src={images.GOLDCROWN}
            alt="first-medal"
          />
        </div>
        {winUser?.achievements?.length ? (
          <AchievementModalBoard data={winUser?.achievements} />
        ) : null}
      </div>
      <div className="w-full sm:w-48p">
        <div className="relative">
          <UserAvatar avatar={loseUser?.init?.avatar} name={loseUser?.name} />
          <img
            className="absolute top-0 right-0 w-8 h-8"
            src={images.SNDBADGE}
            alt="first-medal"
          />
        </div>
        {loseUser?.achievements?.length ? (
          <AchievementModalBoard data={loseUser?.achievements} />
        ) : null}
      </div>
    </div>
  );
};

export default ResultAchievements;
