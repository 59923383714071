import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { fetchResultAll } from "../app/resultSlice";
import { updateAllUsers, fetchUserInfo } from "../app/authSlice";
import { postEvent } from "../app/eventSlice";
import { transformTableData } from "../helper/helper";
import { usePrice } from "./usePrice";

import socket from "../socket";

const COIN_THRESHOLD = 0.5;
const COIN_DECREMENT_FULL = 1;
const COIN_DECREMENT_HALF = 0.5;

const updateUserStatus = (dispatch, users, status) => {
  users.forEach((user) => {
    dispatch(updateAllUsers({ ...user, status }));
  });
};

const handleCoinUpdate = (
  updateCustomPrice,
  updateGlobalCoin,
  user,
  customPrice,
  defaultPrice,
  globalCoins,
  decrement
) => {
  if (customPrice > COIN_THRESHOLD) {
    updateCustomPrice(user._id, "custom", -decrement);
  } else if (defaultPrice > COIN_THRESHOLD) {
    updateCustomPrice(user._id, "default", -decrement);
  } else if (globalCoins > COIN_THRESHOLD) {
    updateGlobalCoin(-decrement);
  }
};

export const useResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results, isLoading } = useSelector((state) => state.result);
  const { user, allUsers } = useSelector((state) => state.auth);
  const {
    customPrice,
    defaultPrice,
    globalCoins,
    updateCustomPrice,
    updateGlobalCoin,
  } = usePrice();

  const [imgSize, setImgSize] = useState(10);
  const [isOnlineShow, setIsOnlineShow] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isChallengeLoading, setIsChallengeLoading] = useState(false);
  const [opponent, setOpponent] = useState(null);

  useEffect(() => {
    dispatch(fetchResultAll());
  }, [dispatch]);

  const handleQuickAcceptResponse = useCallback(
    ({ paymentOption, token, opponent, type, scoringOption }) => {
      setIsChallengeLoading(false);

      const decrement =
        paymentOption === "payAll" ? COIN_DECREMENT_FULL : COIN_DECREMENT_HALF;

      handleCoinUpdate(
        updateCustomPrice,
        updateGlobalCoin,
        user,
        customPrice,
        defaultPrice,
        globalCoins,
        decrement
      );

      dispatch(
        postEvent({
          eventType: "quick-token",
          user: user.username,
          targetUser: opponent,
          token,
        })
      );

      dispatch(fetchUserInfo());

      if (scoringOption === "lidarts") {
        const newWindow = window.open(
          `https://lidarts.org/game/create?opponent_name=${encodeURIComponent(
            opponent?.toLowerCase()
          )}`,
          "_blank"
        );
        if (!newWindow) {
          toast.warning(
            "Pop-up blocked. Please allow pop-ups and redirects for this site."
          );
        }
        if (type === 'quick') {
          navigate(`/result/${token}/quick`);
        } else if (type === 'top-quick') {
          navigate(`/result/${token}/top-quick`);
        }
      } else {
        navigate(
          `/scoring/${opponent?.toLowerCase()}/${user.username?.toLowerCase()}/${token}`
        );
      }
    },
    [
      customPrice,
      defaultPrice,
      globalCoins,
      navigate,
      updateCustomPrice,
      updateGlobalCoin,
      user,
      dispatch,
    ]
  );

  const handleChallengeDecline = useCallback(
    ({ from }) => {
      setIsChallengeLoading(false);

      const occupiedUsers = allUsers.filter(
        (val) => val._id === from || val._id === user?._id
      );
      if (occupiedUsers.length > 0) {
        updateUserStatus(dispatch, occupiedUsers, "online");
      }
    },
    [allUsers, dispatch, user]
  );

  useEffect(() => {
    if (!isChallengeLoading) return;

    socket.on("quick-accept-response", handleQuickAcceptResponse);
    socket.on("challenge-decline-response", handleChallengeDecline);

    return () => {
      socket.off("quick-accept-response", handleQuickAcceptResponse);
      socket.off("challenge-decline-response", handleChallengeDecline);
    };
  }, [isChallengeLoading, handleQuickAcceptResponse, handleChallengeDecline]);

  const transformedResults = useMemo(
    () => transformTableData(results),
    [results]
  );

  const onSliderChange = (e) => {
    setImgSize(e.target.value);
  };

  const handleWaitingCancel = () => {
    setIsChallengeLoading(false);
    if (opponent) {
      const occupiedUsers = allUsers.filter(
        (val) =>
          val.username.toLowerCase() === opponent.toLowerCase() ||
          val._id === user?._id
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      dispatch(
        postEvent({
          eventType: "cancel",
          user: user.username,
        })
      );

      socket.emit("cancel-challenge", {
        message: `${user?.username} cancelled the challenge.`,
        toId: allUsers.find(
          (val) => val.username?.toLowerCase() === opponent?.toLowerCase()
        )?._id,
        fromId: user._id,
      });
    }
  };

  const sendQuickFight = async (
    username,
    challenger,
    paymentOption,
    scoringOption,
    type
  ) => {
    if (
      (paymentOption === "payAll" &&
        customPrice <= COIN_THRESHOLD &&
        defaultPrice <= COIN_THRESHOLD &&
        globalCoins <= COIN_THRESHOLD) ||
      (paymentOption === "payHalf" &&
        customPrice <= 0 &&
        defaultPrice <= 0 &&
        globalCoins <= 0)
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    }

    dispatch(
      postEvent({
        eventType: "quick",
        user: challenger,
        targetUser: username,
      })
    );

    setIsChallengeLoading(true);
    setOpponent(username);

    const occupiedUsers = allUsers.filter(
      (val) =>
        val.username.toLowerCase() === username?.toLowerCase() ||
        val.username.toLowerCase() === challenger?.toLowerCase()
    );

    if (occupiedUsers.length > 0) {
      updateUserStatus(dispatch, occupiedUsers, "occupied");
    }

    let message = "";

    if (type === "top-quick") {
      message = `${challenger} has sent you the challenge. The result of this challenge will not affect your ranking. It is just for fun. Enjoy!`;
    } else {
      message = `${challenger} has sent you the quick challenge with the ${paymentOption} option. (${scoringOption})`;
    }

    socket.emit("challenge", {
      message,
      toId: allUsers?.find(
        (val) => val.username?.toLowerCase() === username?.toLowerCase()
      )?._id,
      fromId: allUsers?.find(
        (val) => val.username?.toLowerCase() === challenger?.toLowerCase()
      )?._id,
      to: username,
      from: challenger,
      paymentOption,
      scoringOption,
      type,
    });
  };

  const sendScheduledFight = (
    selectedDate,
    challenger,
    challengerEmail,
    receiver,
    receiverEmail,
    paymentOption,
    scoringOption
  ) => {
    dispatch(
      postEvent({
        eventType: "schedule",
        user: challenger,
        targetUser: receiver,
      })
    );

    const opponent = allUsers.find(
      (val) => val.username?.toLowerCase() === receiver?.toLowerCase()
    );

    if (
      (paymentOption === "payAll" &&
        customPrice <= COIN_THRESHOLD &&
        defaultPrice <= COIN_THRESHOLD &&
        globalCoins <= COIN_THRESHOLD) ||
      (paymentOption === "payHalf" &&
        customPrice <= 0 &&
        defaultPrice <= 0 &&
        globalCoins <= 0) ||
      !opponent ||
      (paymentOption === "payHalf" &&
        opponent?.customBalance <= 0 &&
        opponent?.defaultBalance <= 0)
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    }

    const decrement =
      paymentOption === "payAll" ? COIN_DECREMENT_FULL : COIN_DECREMENT_HALF;
    handleCoinUpdate(
      updateCustomPrice,
      updateGlobalCoin,
      user,
      customPrice,
      defaultPrice,
      globalCoins,
      decrement
    );

    if (paymentOption === "payHalf") {
      handleCoinUpdate(
        updateCustomPrice,
        updateGlobalCoin,
        opponent,
        opponent?.customBalance,
        opponent?.defaultBalance,
        globalCoins,
        decrement
      );
    }

    socket.emit("schedule-challenge", {
      message: `${challenger} has sent you the scheduled challenge. Challenge date: ${selectedDate}. Payment option: ${paymentOption}.`,
      to: allUsers.find(
        (val) => val.username?.toLowerCase() === receiver?.toLowerCase()
      )?._id,
      date: selectedDate,
      challenger,
      receiver,
      receiverEmail,
      challengerEmail,
      paymentOption,
      scoringOption,
    });
  };

  return {
    transformedResults,
    results,
    isLoading,
    imgSize,
    isOnlineShow,
    selectedPlayer,
    isChallengeLoading,
    onSliderChange,
    setIsOnlineShow,
    setSelectedPlayer,
    sendQuickFight,
    sendScheduledFight,
    handleWaitingCancel,
  };
};
