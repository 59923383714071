import AvatarComponent from "../AvatarComponent";
import { PlayIcon } from "@heroicons/react/24/solid";

const ScoringUserAvatar = ({ user, currentScore, wins, turn }) => {
  return (
    <div
      className={`relative w-full bg-cover bg-center p-3 font-bebas-neue tracking-widest rounded-md h-fit border ${
        turn ? "border-yellow-500 border-4" : "border-green-500"
      }`}
    >
      <div className="absolute w-full h-full top-0 left-0 dark:bg-[#172127bb] bg-[#0C0D1589] z-10 rounded-md"></div>
      <div className="flex items-center justify-center sm:gap-4 sm:mb-4">
        <div className="flex flex-col sm:gap-1 z-10">
          <p className="text-white font-bold text-lg sm:text-xl uppercase">
            {user?.username}
          </p>
          <p className="text-white font-bold font-quantico text-lg sm:text-3xl uppercase">
            0 {wins}
          </p>
        </div>
        <div className="z-10 hidden sm:visible">
          <AvatarComponent
            username={user?.username}
            avatar={user?.avatar}
            avatarSize={20}
            isRoundedFull={false}
          />
        </div>
      </div>

      <div className="relative flex items-center justify-center text-xl sm:text-4xl text-white z-10">
        {turn ? (
          <p className="absolute top-1/2 -translate-y-1/2 left-0 text-red-600">
            <PlayIcon className="w-4 h-4" />
          </p>
        ) : null}
        {currentScore}
      </div>
    </div>
  );
};

export default ScoringUserAvatar;
