import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import constant from "../../helper/constant";

const AchievementMaxMarks = ({ achievementIcon }) => {
  const { t } = useTranslation();
  return (
    <motion.li
      className="w-full lg:w-48p font-serif rounded-lg p-6 dark:text-white bg-white dark:bg-gray-900"
      variants={constant.TopDownAnimationitem}
    >
      <h2 className="font-semibold mb-4 text-3xl">{t("maximumMarksman")}</h2>
      <div className="flex flex-wrap justify-center items-center gap-x-4 mb-4">
        <p className="font-semibold">{t("achievement")}</p>
        <p className="opacity-70">{t("maxScorer")}</p>
      </div>
      <div>
        <div className="flex justify-center items-center">
          <img
            src={achievementIcon}
            className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-64 xl:h-64 rounded-lg"
            alt="achievement-icon"
            style={{ height: "auto" }}
          />
        </div>
      </div>
    </motion.li>
  );
};

export default AchievementMaxMarks;
