import { useTranslation } from "react-i18next";

const ResultHeader = ({ result }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full p-4 rounded-md mt-4">
      <div className="relative flex flex-wrap gap-4">
        <div className="flex-1 dark:bg-gradient-to-tl dark:from-dark-card-start dark:to-dark-card-end block p-6 bg-green-500 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-white uppercase">
            {result?.p1?.name}
          </h5>
        </div>
        <div className="flex-1 dark:bg-gradient-to-tl dark:from-dark-card-start dark:to-dark-card-end block p-6 bg-green-500 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-white uppercase">
            {result?.p2?.name}
          </h5>
        </div>
      </div>
      <div className="flex bg-gradient-to-tl dark:from-dark-card-start dark:to-dark-card-end flex-column mx-auto w-1/2 mt-4 bg-green-500 rounded-md px-4 py-1.5">
        <div className="flex-1 text-white font-semibold text-2xl text-center">
          {result?.p1?.legs_won}
        </div>
        <div className="flex-1 text-white font-semibold text-xl text-center">
          {t("legs")}
        </div>
        <div className="flex-1 text-white font-semibold text-2xl text-center">
          {result?.p2?.legs_won}
        </div>
      </div>
    </div>
  );
};

export default ResultHeader;
