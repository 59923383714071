import { useEffect } from "react";
import { useParams } from "react-router";
import { ArrowUturnLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import images from "../helper/images";
import ArenaReplyDate from "../components/Arena/ArenaReplyDate";
import useScoringSystem from "../hooks/Scoring/useScoringSystem";
import ScoringAvatarContainer from "../components/Scoring/ScoringAvatarContainer";
import ScoringInputBoard from "../components/Scoring/ScoringInputBoard";
import ConfirmModal from "../components/ComfirmModal";
import BullsModal from "../components/Scoring/BullsModal";
import ScoringModal from "../components/Scoring/ScoringModal";
import { Keyboard, X } from "lucide-react";

const ScoringPage = () => {
  const { opponent, challenger, token } = useParams();

  const {
    user,
    opponentUser,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    modalType,
    isModalOpen,
    isFinished,
    isFinishModal,
    isBackModal,
    isBullModalOpen,
    isBullModalWaiting,
    isKeyboardVisible,
    isEnableKeyEvent,
    isUndo,
    fetchUserScore,
    handleSubmit,
    handleGameFinish,
    handleFinishClick,
    handleBack,
    setIsBackModal,
    setIsFinishModal,
    setIsBullModalOpen,
    setIsModalOpen,
    handleBullScoreSubmit,
    handleSubmitModal,
    handleUndo,
    onChangeKeyboardVisible,
    onClickUndo,
  } = useScoringSystem(opponent, challenger, token);

  useEffect(() => {
    fetchUserScore();
  }, [fetchUserScore]);

  return (
    <div
      className="relative flex flex-col w-full h-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${images?.ARENAREPLYBG})` }}
    >
      <div className="absolute w-full h-full top-0 left-0 dark:bg-[#171927bb] bg-[#0C0D15aa] z-10"></div>
      <div className="flex flex-row items-center sm:items-start px-8 z-10 mt-10 sm:mt-24 gap-2">
        <h3 className="text-white text-4xl sm:text-6xl font-bebas-neue tracking-widest font-semibold uppercase">
          Leg <span className="text-green-500">{legNo}</span>
        </h3>
        <button
          className="border border-white bg-green-500 hover:border-green-700 rounded-md py-1 sm:py-2 px-2 sm:px-4 ml-auto text-white font-semibold"
          onClick={() => setIsFinishModal(true)}
        >
          <XMarkIcon className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
        <button
          className="border border-white bg-green-500 hover:border-green-700 rounded-md py-1 sm:py-2 px-2 sm:px-4 mr-0 sm:mr-4 text-white font-semibold"
          onClick={() => setIsBackModal(true)}
        >
          <ArrowUturnLeftIcon className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
      </div>

      <div className="flex justify-center sm:justify-start mt-2 sm:mt-4 px-8 z-10">
        <ArenaReplyDate date={new Date()} />
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-8 px-4 xl:px-8 py-2 sm:py-8">
        <div className="sm:order-1">
          <ScoringAvatarContainer
            user={user}
            userCurrentScore={userCurrentScore}
            userHistory={userScoreHistory[legNo - 1]}
            wins={userWin}
            turn={userTurn}
          />
        </div>
        <div
          className={`fixed bottom-4 sm:bottom-0 left-1/2 -translate-x-1/2 sm:relative order-last col-span-2 sm:order-2 sm:col-span-1 xl:col-span-2 z-20 ${
            isKeyboardVisible ? "visible" : "hidden"
          } sm:block`}
        >
          <ScoringInputBoard
            handleSubmit={handleSubmit}
            handleGameFinish={handleGameFinish}
            handleUndo={handleUndo}
            onClickUndo={onClickUndo}
            turn={userTurn}
            isUndo={isUndo}
            isFinished={isFinished}
            isEnableKeyEvent={isEnableKeyEvent}
          />
        </div>
        <div className="sm:order-3">
          <ScoringAvatarContainer
            user={opponentUser}
            userCurrentScore={opponentCurrentScore}
            userHistory={opponentScoreHistory[legNo - 1]}
            wins={opponentWin}
            turn={!userTurn}
          />
        </div>
      </div>

      <button
        className={`fixed right-2 bottom-2 ${
          isKeyboardVisible ? "bg-red-500" : "bg-green-500"
        } w-10 h-10 flex items-center justify-center rounded-full visible sm:hidden z-10`}
        onClick={onChangeKeyboardVisible}
      >
        {isKeyboardVisible ? <X color="white" /> : <Keyboard color="white" />}
      </button>

      {isModalOpen && (
        <ScoringModal
          isOpen={isModalOpen}
          modalType={modalType}
          onClose={() => setIsModalOpen(false)}
          handleSubmit={handleSubmitModal}
        />
      )}
      {isBullModalOpen && (
        <BullsModal
          isOpen={isBullModalOpen}
          isWaiting={isBullModalWaiting}
          onClose={() => setIsBullModalOpen(false)}
          handleSubmit={handleBullScoreSubmit}
        />
      )}
      <ConfirmModal
        isOpen={isFinishModal}
        modalType={"warning"}
        content={"Are you sure you want to stop this fight?"}
        onClose={() => setIsFinishModal(false)}
        handleConfirm={handleFinishClick}
      />
      <ConfirmModal
        isOpen={isBackModal}
        modalType={"warning"}
        content={"Are you sure you want to back?"}
        onClose={() => setIsBackModal(false)}
        handleConfirm={handleBack}
      />
    </div>
  );
};

export default ScoringPage;
